.error {
  color: #f00;
  font-size: 0.8rem;
  animation: appearFromBottomAnimation 75ms ease-out;
}

.log-in {
  text-align: center;
  padding-top: 10em;
  animation: appearFromBottomAnimation 75ms ease-out;
}

.small {
  font-size: 0.5rem;
}

.user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 9em;
  height: 3em;
  background: #fff0;

  float: right;
  user-select: none;
}

.user-logout {
  font-size: 0.3em;
  margin-right: 1em;
  animation: appearFromBottomAnimation 75ms ease-out;
}

.user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
  line-height: 1;
  animation: appearFromBottomAnimation 75ms ease-out;
}

.user-info-subname {
  font-size: 0.6em;
  animation: appearFromBottomAnimation 75ms ease-out;
}

.logging-in {
  float: right;
  animation: appearFromBottomAnimation 75ms ease-out;
}

.user-avatar {
  width: 2em;
  height: 2em;
  border-radius: 100%;
  animation: popInAnimation 100ms ease-out;
  animation-delay: 200ms;
  animation-fill-mode: both;
}

.user-avatar-error {
  background: #0ff;
}

.connect {
  font-size: 0.8em;
  margin: 0;
  animation: appearFromBottomAnimation 75ms ease-out;
  user-select: none;
}

.disconnect {
  font-size: 0.5rem;
  user-select: none;
}

.logs {
  font-size: 0.6em;
  top: 400px;
  width: 200px;
  overflow-wrap: break-word;
  user-select: none;
}

.log {
  animation: appearFromTopAnimation 175ms ease-out;
}

.log-announce {
  color: #ff70f6;
}

.log-info-happy {
  color: #80ffab;
}

.log-info-neutral {
  color: #979d99;
}

.log-error {
  color: orange;
}

.log-error {
  color: red;
}

.send-message {
  font-size: 0.5rem;
  margin-right: 0.5rem;
}

.canvas {
  position: absolute;
  inset: 0;
  margin: auto;
  z-index: -1;
}

.user-side-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: absolute;
  top: 0;
  right: 0;
}

@keyframes appearFromBottomAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appearFromTopAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popInAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

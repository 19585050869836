.error {
  color: red;
  font-size: .8rem;
  animation: 75ms ease-out appearFromBottomAnimation;
}

.log-in {
  text-align: center;
  padding-top: 10em;
  animation: 75ms ease-out appearFromBottomAnimation;
}

.small {
  font-size: .5rem;
}

.user {
  width: 9em;
  height: 3em;
  float: right;
  -webkit-user-select: none;
  user-select: none;
  background: #fff0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.user-logout {
  margin-right: 1em;
  font-size: .3em;
  animation: 75ms ease-out appearFromBottomAnimation;
}

.user-info {
  flex-direction: column;
  justify-content: center;
  margin-left: .5em;
  margin-right: .5em;
  line-height: 1;
  animation: 75ms ease-out appearFromBottomAnimation;
  display: flex;
}

.user-info-subname {
  font-size: .6em;
  animation: 75ms ease-out appearFromBottomAnimation;
}

.logging-in {
  float: right;
  animation: 75ms ease-out appearFromBottomAnimation;
}

.user-avatar {
  width: 2em;
  height: 2em;
  border-radius: 100%;
  animation: .1s ease-out .2s both popInAnimation;
}

.user-avatar-error {
  background: #0ff;
}

.connect {
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  font-size: .8em;
  animation: 75ms ease-out appearFromBottomAnimation;
}

.disconnect {
  -webkit-user-select: none;
  user-select: none;
  font-size: .5rem;
}

.logs {
  width: 200px;
  overflow-wrap: break-word;
  -webkit-user-select: none;
  user-select: none;
  font-size: .6em;
  top: 400px;
}

.log {
  animation: .175s ease-out appearFromTopAnimation;
}

.log-announce {
  color: #ff70f6;
}

.log-info-happy {
  color: #80ffab;
}

.log-info-neutral {
  color: #979d99;
}

.log-error {
  color: orange;
  color: red;
}

.send-message {
  margin-right: .5rem;
  font-size: .5rem;
}

.canvas {
  z-index: -1;
  margin: auto;
  position: absolute;
  inset: 0;
}

.user-side-bar {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

@keyframes appearFromBottomAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appearFromTopAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popInAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*# sourceMappingURL=index.2f9d8dcf.css.map */
